import React from 'react'
import { Link } from 'gatsby'
import { withGrid } from './layout'
import { LogoIcon, SocialEmailIcon, SocialFacebookIcon, SocialInstagramIcon, SocialLinkedInIcon, SocialYouTubeIcon } from './icons'
import Obfuscate from 'react-obfuscate'

const NavDecoComponent = props => {
  const { style = {}, ...passThroughProps } = props
  return <div {...passThroughProps} style={{ ...style, background: 'linear-gradient(174deg, #00edae 35%, #b3ff00)' }} />
}
const NavDeco = withGrid(NavDecoComponent)

const WiseQuotesData = [
  {
    quote: 'Over every mountain, there is a path, although it may not be seen from the valley.',
    author: 'Theodore Roethke',
  },
  {
    quote: 'Life is like riding a bicycle. To keep your balance you must keep moving',
    author: 'Albert Einstein',
  },
  {
    quote: 'Do not follow where the path may lead. Go instead where there is no path and leave a trail.',
    author: 'Ralph Waldo Emerson',
  },
  {
    quote: 'Somewhere between the bottom of the climb and the summit is the answer to the mystery why we climb.',
    author: 'Greg Child',
  },
  {
    quote: 'Every mountain top is within reach if you just keep climbing.',
    author: 'Barry Finlay',
  },
  {
    quote: 'It is one thing to decide to climb a mountain. It is quite another to be on top of it.',
    author: 'Herbert A. Simon',
  },
  {
    quote: 'Imagination will often carry us to worlds that never were. But without it we go nowhere.',
    author: 'Carl Sagan',
  },
  {
    quote: 'I learned very early the difference between knowing the name of something and knowing something.',
    author: 'Richard Feynman',
  },
  {
    quote: 'Ride as much or as little, or as long or as short as you feel. But ride.',
    author: 'Eddy Merckx',
  },
]

const randomQuote = () => {
  const quoteIndex = Math.floor(Math.random() * WiseQuotesData.length)
  return WiseQuotesData[quoteIndex]
}

export const NavContentComponent = props => {
  const quote = randomQuote()
  return (
    <nav {...props}>
      <div className="relative">
        <div className="m-auto pt-24 w-full flex flex-wrap lg:flex-no-wrap align-top text-lg">
          <div className="flex-auto w-1/2 lg:flex-1">
            <Link to="/">Home</Link>
            <Link to="/services/" className="block mt-4">
              Services
            </Link>
            <Link to="/services/#ownership" className="block font-normal tracking-normal">
              Tech Ownership
            </Link>
            <Link to="/services/#growth" className="block font-normal tracking-normal">
              Product Growth
            </Link>
            <Link to="/services/#extension" className="block font-normal tracking-normal">
              Team Extensions
            </Link>
            <Link to="/services/#poc" className="block font-normal tracking-normal">
              Proof of Concept
            </Link>
          </div>
          <div className="flex-auto w-1/2 lg:flex-1 text-lg">
            <div className="">&nbsp;</div>
            <Link to="/industries/" className="block mt-4">
              Industries
            </Link>
            <Link to="/industries/vr/" className="block font-normal tracking-normal">
              VR & 3D
            </Link>
            <Link to="/industries/autotech/" className="block font-normal tracking-normal">
              AutoTech
            </Link>
            <Link to="/industries/digitalcity/" className="block font-normal tracking-normal">
              Digital City
            </Link>
            <Link to="/industries/#media" className="block font-normal tracking-normal">
              Digital Media
            </Link>
            <Link to="/industries/#health" className="block font-normal tracking-normal">
              Digital Health
            </Link>
          </div>
          <div className="mt-4 lg:mt-0 flex-auto w-1/2 lg:flex-1 text-lg">
            <Link to="/team/" className="block">
              Team
            </Link>
            <Link to="/expertise/" className="block mt-4">
              Products
            </Link>
            <Link to="/expertise/#technologies" className="block mt-4">
              Technologies
            </Link>
            <Link to="/manifesto/" className="block mt-4">
              Manifesto
            </Link>
          </div>
          <div className="mt-4 lg:mt-0 flex-auto w-1/2 lg:flex-1 text-lg">
            <Link to="/careers/" className="block">
              Careers
            </Link>
            <Link to="/meet-us/events/" className="block mt-4">
              Events
            </Link>
            <Link to="/meet-us/feed/" className="block mt-4">
              Feed
            </Link>
            <Link to="/meet-us/" className="block mt-4">
              Meet us
            </Link>
          </div>
          <div className="mt-8 lg:mt-0 flex-auto w-1/2 lg:flex-1 flex items-start">
            <a href="https://www.facebook.com/tumba.solutions" target="_blank" rel="me noreferrer" aria-label="Facebook">
              <SocialFacebookIcon className="h-10" />
            </a>
            <a href="https://www.instagram.com/tumba.solutions" target="_blank" rel="me noreferrer" aria-label="Instagram">
              <SocialInstagramIcon className="h-10" />
            </a>
            <a href="https://www.linkedin.com/company/tumba-solutions-ltd-/" target="_blank" rel="me noreferrer" aria-label="LinkedIn">
              <SocialLinkedInIcon className="h-10" />
            </a>
            <Obfuscate email="contact+www@tumba.solutions" headers={{ subject: 'Ask Tumba' }} aria-label="Email us">
              <SocialEmailIcon className="h-10" />
            </Obfuscate>
            <a href="https://www.youtube.com/channel/UCce0nOFno-f5mfEhG9OwiHQ" target="_blank" rel="me noreferrer" aria-label="YouTube">
              <SocialYouTubeIcon className="h-10" />
            </a>
          </div>
        </div>
        <div data-testid="quote" className="m-auto mt-48 flex flex-col items-center text-xs text-center">
          <span className="w-5/6 sm:w-1/3 font-normal tracking-normal">"{quote.quote}"</span>
          <span className="inline-block pt-4">{quote.author}</span>
          <LogoIcon className="w-8 mt-8" />
        </div>
      </div>
    </nav>
  )
}
export const NavContent = withGrid(NavContentComponent)

export const Nav = props => (
  <>
    <NavDeco gridColumn={1} {...props} />
    <NavContent gridColumn={2} {...props} />
  </>
)
