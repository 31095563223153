import React from 'react'
import Helmet from 'react-helmet'
import { isNetlifyProduction, siteUrl } from '../utils/environment'

const domain = siteUrl ? siteUrl.match(/https?:\/\/(www\.)?(.*)/i)?.[2] : null

const Analytics = () => {
    return (
        <>
            {isNetlifyProduction && (
                <Helmet>
                    <script defer data-domain={domain} data-api="/pl/api/event" src="/pl/js/script.tagged-events.js"></script>
                </Helmet>
            )}
        </>
    )
}

export default Analytics